import React, { ReactNode, useState, useImperativeHandle } from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "@material-ui/core/Popover";
import { styled } from "@mui/material";

interface CustomPopoverProps {
  children: ReactNode;
  hideButton?: boolean;
  identifier: string;
}

export interface CustomPopoverRef {
  closePopover: () => void;
}

export const Container = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

function CustomPopover(
  { children, hideButton = false, identifier = '' }: CustomPopoverProps,
  ref: React.Ref<CustomPopoverRef>
) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useImperativeHandle(ref, () => ({
    closePopover: handlePopoverClose,
  }));

  return (
    <Container>
      {!hideButton && (
        <IconButton
          onClick={handlePopoverOpen}
          aria-label="Actions"
          aria-haspopup="true"
          data-cy={`popover-button-${identifier}`}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        data-cy={`popover-${identifier}`}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "10px",
          }}
          data-cy={`popover-content-${identifier}`}
        >
          {children}
        </div>
      </Popover>
    </Container>
  );
}

export default React.forwardRef(CustomPopover);
