/* eslint-disable prefer-spread */
import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  ExpandedState,
  FilterFn,
  PaginationState,
  SortingState,
  Table,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classnames from "classnames";
import React, { ChangeEvent } from "react";

import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import { useTranslation } from "react-i18next";

import { FormControl, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";

type TableState = {
  pageIndex: number;
  pageSize: number;
  columnFilters: ColumnFiltersState;
  sorting: SortingState;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const newStyles: any = {
  ...styles,
  formControlMargins: {
    margin: "3px 0 !important",
  },
  gridContainer: {
    justifyContent: "center",
  },
};

const useStyles = makeStyles(newStyles);

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

interface EntityTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<any, any>[];
  setTableState: React.Dispatch<React.SetStateAction<TableState>>;
  tableState: TableState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  pageCount: number;
  fixedPageSize?: boolean;
  refetchNextPage?: () => void;
  refetchPreviousPage?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
  hasHeader?: boolean;
  hasFilters?: boolean;
}

export function ManualPaginatedTableExpanded({
  columns,
  data,
  tableState,
  setTableState,
  pageCount,
  fixedPageSize = false,
  refetchNextPage,
  refetchPreviousPage,
  ref,
  hasHeader = true,
  hasFilters = true,
}: EntityTableProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("common");

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    tableState.columnFilters
  );
  const [globalFilter, setGlobalFilter] = React.useState("");

  const [sorting, setSorting] = React.useState<SortingState>(tableState.sorting);

  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: tableState.pageIndex,
    pageSize: tableState.pageSize,
  });

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      columnFilters,
      globalFilter,
      expanded,
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => {
      return row.subRows;
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    maxLeafRowFilterDepth: 0,
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
    manualPagination: true,
    enableMultiSort: true,
  });

  React.useEffect(() => {
    if (pageIndex > pageCount) table.setPageIndex(0);
    setTableState({
      pageIndex: pageIndex > pageCount ? 0 : pageIndex,
      pageSize: pageSize,
      columnFilters: columnFilters,
      sorting: sorting,
    });
  }, [pageIndex, pageSize, sorting, columnFilters]);

  return (
    <div className='ReactTable -striped -highlight '>
      <div className='h-2' />
      <table className='rt-table' ref={ref}>
        {hasHeader && (
          <thead className='rt-thead -header'>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className='rt-tr'>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={classnames("rt-th rt-resizable-header")}
                      style={{ margin: 0 }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>
                          <div
                            onClick={header.column.getToggleSortingHandler()}
                            style={{
                              cursor: header.column.getCanSort() ? "pointer" : "default",
                            }}
                            className='rt-resizable-header-content'
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: <KeyboardArrowUp />,
                              desc: <KeyboardArrowDown />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {header.column.getCanFilter() && hasFilters ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
        )}
        <tbody className='rt-tbody'>
          {table.getRowModel().rows.map((row, i) => {
            return (
              <tr
                key={row.id}
                className={classnames("rt-tr", { " -odd": i % 2 === 0 }, { " -even": i % 2 === 1 })}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className='rt-td'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className='pagination-top' style={{ padding: 20 }}>
        <div className='-pagination'>
          <div className='-previous'>
            <button
              onClick={() => table.previousPage()}
              disabled={pageIndex === 0}
              onMouseEnter={refetchPreviousPage}
              className='-btn'
            >
              {t("react-previous")}
            </button>
          </div>

          <div className='-center'>
            <GridContainer className={classes.gridContainer}>
              <GridItem>
                {!!pageCount && (
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl + " " + classes.formControlMargins}
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      style={{ width: "100%" }}
                      value={pageIndex}
                      onChange={(e) => {
                        table.setPageIndex(Number(e.target.value));
                      }}
                      label={t("react-page")}
                      inputProps={{
                        name: "pageSelect",
                        id: "page-select",
                      }}
                    >
                      {Array?.from(Array(pageCount)?.keys())?.map(
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (key: any) => {
                          return (
                            <MenuItem
                              key={key}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={key}
                            >
                              {t("react-page")} {key + 1}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                )}
              </GridItem>
              {!fixedPageSize && (
                <GridItem>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl + " " + classes.formControlMargins}
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      variant='standard'
                      value={pageSize}
                      onChange={(e) => {
                        table.setPageSize(Number(e.target.value));
                      }}
                      label={t("react-row")}
                      inputProps={{
                        name: "numberOfRows",
                        id: "number-of-rows",
                      }}
                    >
                      {[5, 10, 20, 25, 50]?.map((pageSize) => (
                        <MenuItem
                          key={pageSize}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={pageSize}
                        >
                          {pageSize} {t("react-row")}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              )}
            </GridContainer>
          </div>

          <div className='-next'>
            <button
              className='-btn'
              onClick={() => table.nextPage()}
              disabled={tableState.pageIndex + 1 === pageCount}
              onMouseEnter={refetchNextPage}
            >
              {t("react-next")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Filter({
  column,
  table,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: Table<any>;
}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [, setClientConfig] = React.useState<any>();

  React.useEffect(() => {
    setClientConfig(JSON.parse(localStorage.getItem("clientConfig")));
  }, []);

  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === "number" ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[0] ?? ""}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ""
          }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[1] ?? ""}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ""
          }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder='Search'
        className='w-36 border shadow rounded'
      />
    </>
  );
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 1000,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);
  const { t } = useTranslation("common");

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <CustomInput
      {...props}
      formControlProps={{
        fullWidth: true,
        style: { paddingTop: 0 },
      }}
      inputProps={{
        value: value,
        onChange: (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value),
        placeholder: `${t("react-search")}`,
      }}
    />
  );
}
