import React from "react";
import InviteUser from "./InviteUser/InviteUser";
import { RolesEnum } from "utils/@types";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer";
import GridItemTS from "components/Grid/GridItemTS";
import useHasRole from "hooks/useHasRole";
import TabSystem from "components/TabsRadix/TabSystem";
import TabContent from "components/TabsRadix/TabContent";
import UsersList from "./UsersList/UsersList";

const Users: React.FC = () => {
  const { hasResourceRole } = useHasRole();
  const canSeeTab1 = hasResourceRole([RolesEnum.SUPPLIER_PRIMARY_CONTACT]);

  const { t } = useTranslation("user-list");

  const tabs = [
    {
      id: "tab1",
      title: t("new-user"),
      visible: canSeeTab1,
    },
    {
      id: "tab2",
      title: t("users-list"),
    },
  ].filter((tab) => (tab.visible !== undefined ? tab.visible : true));

  return (
    <GridContainer>
      <GridItemTS xs={12} style={{ marginTop: "1rem" }}>
        <TabSystem tabs={tabs}>
          <TabContent value='tab1'>
            <InviteUser />
          </TabContent>
          <TabContent value='tab2'>
            <UsersList />
          </TabContent>
        </TabSystem>
      </GridItemTS>
    </GridContainer>
  );
};

export default Users;
