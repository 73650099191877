import { PermIdentity } from "@material-ui/icons";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useInviteUsers } from "services/input-core/users/invite-users";

const InviteUser = () => {
  const { t } = useTranslation("user-list");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [emails, setEmails] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { mutate: inviteUsers } = useInviteUsers();

  const transformEmails = (emails: string[]) => {
    return emails.map((email: string) => ({
      email,
      username: email,
    }));
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    const transformedData = transformEmails(emails);
    inviteUsers(transformedData, {
      onSuccess: () => {
        setEmails([]);
        toast.success(t("Invitation sent successfully"));
        setIsLoading(false);
      },
      onError: (error) => {
        toast.error(t("Error sending invitation"));
        setIsLoading(false);
      },
    });
  };

  const handleBlur = () => {
    const emailInput = inputRef.current;
    if (emailInput && emailInput.value.trim() !== "") {
      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        code: "Enter",
        keyCode: 13,
        bubbles: true,
        cancelable: true,
      });
      emailInput.dispatchEvent(event);
    }
  };

  return (
    <GridContainer>
      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <PermIdentity />
          </CardIcon>
        </CardHeader>
        <Box p={2}>
          <Box style={{ margin: "20px 0" }}>
            <Alert severity='info'>
              {t("Use our email invite page to easily invite users to join Uverify.")}
            </Alert>
          </Box>
          <form onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} md={8}>
                <Autocomplete
                  multiple
                  disabled={isLoading}
                  options={[]}
                  freeSolo
                  value={emails}
                  onChange={(event, values) => {
                    setEmails(values.map((value: string) => value.trim()));
                  }}
                  onBlur={handleBlur}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant='outlined' label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={isLoading}
                      inputRef={inputRef}
                      label='E-mail(s)'
                      placeholder={t("type-an-email-and-press-enter")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Box width={160}>
                  <Button
                    style={{ height: "56px" }}
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    disabled={isLoading}
                  >
                    {t("send-invite")}
                    {isLoading && (
                      <CircularProgress style={{ color: "white", marginLeft: "10px" }} size={20} />
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
    </GridContainer>
  );
};

export default InviteUser;
