import { useMutation } from "@tanstack/react-query";
import apiUpdateService from "services/apiUpdateService";
import { IUserDisabled } from "./@types";

const disabledUsers = async (dataUpdate: IUserDisabled) => {
  const { data } = await apiUpdateService.put(
    `/users/changeUserStatus?realm=${process.env.REACT_APP_API_AUTH_REALM}`,
    dataUpdate
  );

  return data;
};

export const useDisabledUsers = (options?: any) => {
  return useMutation(disabledUsers, {
    ...options,
  });
};
