import { PaginatedParams, PaginatedResultV2 } from "services/@types";
import apiInputService from "services/apiInputService";
import { useQuery } from "@tanstack/react-query";
import { useSubscription } from "hooks/useSubscription";
import { IUserRequest } from "./@types";
import useHasRole from "hooks/useHasRole";
import { RolesEnum } from "utils/@types";

const fetchGetUsers = async (
  params: PaginatedParams,
  userBuyer: boolean
): Promise<PaginatedResultV2<IUserRequest>> => {
  const auxParams = userBuyer
    ? {
        ...params,
        "filter[userType]": params["filter[userType]"] ?? [
          RolesEnum.BUYER,
          RolesEnum.SUPPLIER_PRIMARY_CONTACT,
        ],
        "filter[realm]": process.env.REACT_APP_API_AUTH_REALM,
      }
    : { ...params, "filter[realm]": process.env.REACT_APP_API_AUTH_REALM };

  const users = await apiInputService.get(`/users/paginated`, {
    params: auxParams,
  });
  const { data } = users;

  return data._metadata as PaginatedResultV2<IUserRequest>;
};

export const useGetUsers = (
  params?: PaginatedParams,
  options?: {
    staleTime: number;
    onSuccess: (data: PaginatedResultV2<IUserRequest>) => void;
  }
) => {
  const { subscriptionsAvailable } = useSubscription();

  const { hasResourceRole } = useHasRole();

  const userBuyer = hasResourceRole([RolesEnum.BUYER, RolesEnum.STRATESYS]);

  return useQuery(
    ["user-paginated", subscriptionsAvailable[0]?.subscriptionId, params],
    () => fetchGetUsers(params, userBuyer),
    {
      ...options,
      enabled: !!subscriptionsAvailable[0]?.subscriptionId,
      keepPreviousData: true,
      cacheTime: 0.15 * 60 * 1000,
    }
  );
};
