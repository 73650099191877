import { useMutation } from "@tanstack/react-query";
import apiInputService from "services/apiInputService";
import { InviteUserRequest } from "./@types";

const inviteUsers = async (payload: InviteUserRequest[]) => {
  const { data } = await apiInputService.post(
    `/users/inviteSupplierUsers?realm=${process.env.REACT_APP_API_AUTH_REALM}`,
    payload
  );

  return data;
};

export const useInviteUsers = (options?: any) => {
  return useMutation(inviteUsers, {
    ...options,
  });
};
