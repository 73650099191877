import { PaginatedParams, PaginatedResultV2 } from "services/@types";
import apiInputService from "services/apiInputService";
import { useQuery } from "@tanstack/react-query";
import { useSubscription } from "hooks/useSubscription";
import { IUserRequest } from "./@types";
import { RolesEnum } from "utils/@types";

const fetchGetUsersSupplier = async (
  params: PaginatedParams,
  supplierCode: string,
  getRows: boolean
): Promise<PaginatedResultV2<IUserRequest>> => {
  const auxParams = {
    ...params,
    "filter[supplierCode]": supplierCode,
    "filter[userType]": RolesEnum.SUPPLIER,
    "filter[realm]": process.env.REACT_APP_API_AUTH_REALM,
  };

  if (getRows && supplierCode) {
    const users = await apiInputService.get(`/users/paginated`, {
      params: auxParams,
    });
    const { data } = users;

    return data._metadata as PaginatedResultV2<IUserRequest>;
  } else {
    return {} as PaginatedResultV2<IUserRequest>;
  }
};

export const useGetUsersSupplier = (
  getRows?: boolean,
  supplierCode?: string,
  params?: PaginatedParams,
  options?: {
    staleTime: number;
    onSuccess: (data: PaginatedResultV2<IUserRequest>) => void;
  }
) => {
  const { subscriptionsAvailable } = useSubscription();

  return useQuery(
    [
      "user-supplier-paginated",
      subscriptionsAvailable[0]?.subscriptionId,
      getRows,
      supplierCode,
      params,
    ],
    () => fetchGetUsersSupplier(params, supplierCode, getRows),
    {
      ...options,
      enabled: !!subscriptionsAvailable[0]?.subscriptionId,
      keepPreviousData: true,
    }
  );
};
