/* eslint-disable no-undef */
import axios from "axios";
import qs from "qs";
import { keycloakService } from "./authService";

const apiUpdateService = axios.create({
  baseURL: process.env.REACT_APP_INPUT_CORE,
  headers: {
    "Content-Type": "application/json",
  },
});

apiUpdateService.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("accessToken");
    // let subscription = localStorage.getItem("subscription");

    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    config.params = {
      ...config.params,
      // subscription: subscription,
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiUpdateService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true;

      const refreshToken = localStorage.getItem("refreshToken");
      const body = qs.stringify({
        client_id: process.env.REACT_APP_API_AUTH_CLIENT_ID,
        refresh_token: refreshToken,
        grant_type: "refresh_token",
        client_secret: process.env.REACT_APP_API_AUTH_CLIENT_SECRET,
      });

      keycloakService.post("/token", body).then((response) => {
        const { access_token } = response.data;

        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("refreshToken", response.data.refresh_token);

        keycloakService.defaults.headers["Authorization"] = `Bearer ${access_token}`;
      });
      return apiUpdateService(prevRequest);
    }

    return Promise.reject(error);
  }
);

export default apiUpdateService;
