import { useCallback, useEffect, useRef, useState } from "react";

//components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";

import GridContainer from "components/Grid/GridContainer";
import GridItemTS from "components/Grid/GridItemTS";

// material-ui icons
import {
  PersonAddDisabled,
  PermIdentity,
  ChevronRight,
  ExpandMore,
  Sync,
  PersonAdd,
} from "@material-ui/icons";

import Skeleton from "react-loading-skeleton";

import { useTranslation } from "react-i18next";

import { ColumnDef } from "@tanstack/react-table";
import { getBadgeFromStatus, getFilters, getSorting } from "utils/functions";
// import { TitlePages } from "components/TitlePage/TitlePages";
import CustomPopover, { CustomPopoverRef } from "components/CustomPopover/CustomPopover";
import ConfirmDisabledModal from "components/ConfirmDisabledUserModal";
import { ManualPaginatedTableExpanded } from "components/ReactTable/ManualPaginatedTableExpanded";

import { useGetUsers } from "services/input-core/users/get-users-paginated";
import { useGetUsersSupplier } from "services/input-core/users/get-users-suppliers-paginated";
import { IUserRequest } from "services/input-core/users/@types";
import { useDisabledUsers } from "services/input-core/users/disabled-users";

import { EUsersStatus, RolesEnum } from "utils/@types";

import useHasRole from "hooks/useHasRole";

import { toast } from "react-toastify";

import { ButtonText } from "./components/styles";
import { makeStyles } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

const useStyles = makeStyles(styles as any);

type ModalsState = {
  userDisabled: {
    open: boolean;
    userName: string;
    userID: string;
    userStatus: boolean;
  };
};

const UsersList = () => {
  const classes = useStyles();
  const { t } = useTranslation("user-list");

  const { hasResourceRole } = useHasRole();

  const userBuyer = hasResourceRole([RolesEnum.BUYER]);

  const [dataTable, setDataTable] = useState<IUserRequest[]>();

  const [tableData, setTableData] = useState({
    pageSize: 20,
    pageIndex: 0,
    columnFilters: [],
    sorting: [],
  });

  const [rowsData, setRowsData] = useState({
    pageSize: 100,
    pageIndex: 0,
    columnFilters: [],
    sorting: [],
    supplierCode: "",
    getRows: false,
  });

  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchUsers,
  } = useGetUsers({
    "page[size]": tableData.pageSize,
    "page[index]": tableData.pageIndex,
    ...getFilters(tableData.columnFilters),
    ...getSorting(tableData.sorting),
  });

  useEffect(() => {
    !isFetching &&
      setDataTable(() => {
        return data?.records?.map((user: any) => {
          return {
            ...user,
            subRows: [],
          };
        });
      });
  }, [data?.records, isFetching]);

  const usersSupplier = useGetUsersSupplier(
    rowsData?.getRows,
    rowsData?.supplierCode,
    {
      "page[size]": rowsData?.pageSize,
      "page[index]": rowsData?.pageIndex,
      ...getFilters(rowsData?.columnFilters),
      ...getSorting(rowsData?.sorting),
    },
    {
      staleTime: 0,
      onSuccess: (data: any) => {
        if (rowsData?.getRows && (data?.records?.length <= 0 || Object.keys(data).length == 0)) {
          toast.warning(t("info-sync-not-users"));
        } else {
          rowsData?.getRows && toast.success(t("success-sync-users"));
        }
      },
    }
  );

  const getUsersSupplier = useCallback((code: string) => {
    setRowsData((values) => ({
      ...values,
      supplierCode: code,
      getRows: true,
    }));
  }, []);

  useEffect(() => {
    !usersSupplier.isFetching &&
      setDataTable((values) => {
        return values?.map((user) => {
          if (user?.attributes[0]?.supplierCode[0] === rowsData?.supplierCode) {
            return { ...user, subRows: usersSupplier?.data?.records };
          } else {
            return {
              ...user,
            };
          }
        });
      });
  }, [usersSupplier?.data?.records, usersSupplier.isFetching]);

  const {
    mutate: disabledUser,
    // isLoading: isDisabledUserLoading,
  } = useDisabledUsers();

  const [modals, setModals] = useState<ModalsState>({
    userDisabled: {
      open: false,
      userName: "",
      userID: "",
      userStatus: false,
      // activityItem: undefined,
    },
  });

  const closeModal = (modalType: keyof ModalsState) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalType]: {
        ...prevModals[modalType],
        open: false,
      },
    }));
  };

  const openModal = (modalType: string, data: any) => {
    setModals({
      ...modals,
      [modalType]: {
        open: true,
        ...data,
      },
    });
  };

  const customPopoverRef = useRef<CustomPopoverRef>(null);

  const closePopoverFromParent = () => {
    if (customPopoverRef.current) {
      customPopoverRef.current.closePopover();
    }
  };

  const columns: ColumnDef<any, any>[] = [
    {
      header: t("user-table-status"),
      footer: (props: any) => props.column.id,
      accessorKey: "status",
      cell: ({ row, getValue }) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            paddingLeft: `${row.depth * 2}rem`,
          }}
        >
          {row.getCanExpand() && row?.original?.userType === RolesEnum.SUPPLIER_PRIMARY_CONTACT ? (
            <button
              onClick={row.getToggleExpandedHandler()}
              style={{
                cursor: "pointer",
                border: "none",
                background: "transparent",
              }}
              disabled={usersSupplier?.isLoading}
            >
              {row.getIsExpanded() ? (
                <ExpandMore style={{ fontSize: "1.5rem" }} />
              ) : (
                <ChevronRight style={{ fontSize: "1.5rem" }} />
              )}
            </button>
          ) : !userBuyer ? (
            <span style={{ width: "1.5rem" }} />
          ) : row.depth >= 0 && row?.original?.userType !== RolesEnum.SUPPLIER_PRIMARY_CONTACT ? (
            <span
              style={{
                width: row.depth == 0 ? "2.5rem" : "1.5rem",
              }}
            />
          ) : (
            <button
              style={{
                border: "none",
                cursor: "pointer",
                background: "transparent",
              }}
              onClick={() => {
                toast.info(t("info-sync-users"));
                rowsData?.supplierCode === row?.original?.attributes[0]?.supplierCode[0]
                  ? usersSupplier.refetch()
                  : getUsersSupplier(row?.original?.attributes[0]?.supplierCode[0]);
              }}
              disabled={usersSupplier?.isLoading}
            >
              <Sync style={{ fontSize: "1.5rem" }} />
            </button>
          )}{" "}
          {getBadgeFromStatus(getValue())}
        </div>
      ),
    },
    {
      header: t("user-table-type"),
      footer: (props: any) => props.column.id,
      accessorKey: "userType",
      cell: (info: any) => getBadgeFromStatus(info.getValue()),
    },
    {
      header: t("user-table-name"),
      footer: (props: any) => props.column.id,
      accessorKey: "name",
    },
    {
      header: t("user-table-email"),
      footer: (props: any) => props.column.id,
      accessorKey: "email",
    },
    {
      header: t("user-table-action"),
      accessorKey: "actions",
      cell: ({ row }: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <CustomPopover identifier={`user-${row.id}`} ref={customPopoverRef}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  padding: "10px",
                }}
              >
                <ButtonText
                  onClick={() => {
                    openModal("userDisabled", {
                      open: true,
                      userName: row.original.name,
                      userID: row.original.userId,
                      userStatus: row.original.status === EUsersStatus.INACTIVE,
                    });
                    closePopoverFromParent();
                  }}
                >
                  {row.original.status === EUsersStatus.INACTIVE ? (
                    <PersonAdd style={{ fontSize: "1.2rem" }} />
                  ) : (
                    <PersonAddDisabled style={{ fontSize: "1.2rem" }} />
                  )}

                  {row.original.status === EUsersStatus.INACTIVE
                    ? t("user-enable")
                    : t("user-disable")}
                </ButtonText>
              </div>
            </CustomPopover>
          </div>
        );
      },
      enableColumnFilter: false,
    },
  ];

  return (
    <>
      {modals.userDisabled.open && (
        <ConfirmDisabledModal
          userName={modals.userDisabled.userName}
          userStatus={modals.userDisabled.userStatus}
          modalOpen={modals.userDisabled.open}
          isDeleting={false}
          onClose={() => {
            closeModal("userDisabled");
          }}
          onConfirm={() => {
            disabledUser(
              {
                userId: modals.userDisabled.userID,
                activate: modals.userDisabled.userStatus,
              },
              {
                onSuccess: () => {
                  toast.success(
                    t(
                      !modals.userDisabled.userStatus
                        ? "success-disable-user"
                        : "success-enable-user"
                    )
                  );
                  refetchUsers();
                },
                onError: (error: any) => {
                  console.log("[error] =>", error);
                  toast.error(t("err-user"));
                },
              }
            );
            closeModal("userDisabled");
          }}
        />
      )}
      <GridContainer>
        <GridItemTS key='user-list-data' xs={12}>
          <Card>
            <CardHeader color='rose' icon>
              <CardIcon color='rose'>
                <PermIdentity />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("users")}</h4>
            </CardHeader>

            <CardBody>
              {isLoading || isFetching ? (
                <Skeleton />
              ) : dataTable?.length > 0 ? (
                <ManualPaginatedTableExpanded
                  columns={columns}
                  data={dataTable || []}
                  tableState={tableData}
                  pageCount={data?.page_count}
                  setTableState={setTableData}
                  // fixedPageSize
                />
              ) : (
                <h5 className={classes.cardIconTitle} style={{ textAlign: "center" }}>
                  {t("no-guest-users")}
                </h5>
              )}
            </CardBody>
          </Card>
        </GridItemTS>
      </GridContainer>
    </>
  );
};

export default UsersList;
