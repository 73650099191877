import { styled } from "@mui/material";

export const ButtonText = styled("button")`
  cursor: pointer;
  padding: 10px;
  transition: background 0.3s;
  min-width: 150px;
  border-radius: 5px;
  font-weight: 400;
  border: none;
  display: flex;
  gap: 5px;
  align-items: center;
  background: white;
  color: #757575;
  text-align: start;

  &:hover {
    background: #0085f2;
    color: white;
  }
`;
