import { useKeycloak } from "@react-keycloak/web";

type UseHasRoleReturn = {
    hasRealmRole: (roles: string[]) => boolean;
    hasResourceRole: (roles: string[]) => boolean;
}


const useHasRole = (): UseHasRoleReturn => {
    const { keycloak } = useKeycloak();

    function hasRealmRole(roles: string[]) {
        for (const role of roles) {
          if (keycloak?.hasRealmRole(role)) {
            return true;
          }
        }
        return false;
      }
    
      function hasResourceRole(roles: string[]) {
        for (const role of roles) {
          if (keycloak?.hasResourceRole(role, "uverify")) {
            return true;
          }
        }
        return false;
      }

        return { hasRealmRole, hasResourceRole }
}

export default useHasRole;