import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Button from "components/CustomButtons/ButtonTS";
import { useTranslation } from "react-i18next";

interface ConfirmDisabledUserModalProps {
  modalOpen: boolean;
  userId?: string;
  userName?: string;
  userStatus?: boolean;
  isDeleting: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function ConfirmDisabledUserModal({
  modalOpen,
  onClose,
  onConfirm,
  userName,
  userStatus,
  isDeleting,
}: ConfirmDisabledUserModalProps): JSX.Element {
  const { t } = useTranslation(["modals", "user-list"]);

  return (
    <Dialog
      open={modalOpen}
      fullWidth
      maxWidth='sm'
      scroll='body'
      onClose={() => {
        onClose();
      }}
      disableBackdropClick
    >
      <DialogTitle>
        {t(userStatus ? "user-enable" : "user-disable", { ns: "user-list" })}
        <Button
          justIcon
          simple
          color='tumblr'
          round
          size='sm'
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
          onClick={onClose}
        >
          <Close />
        </Button>
      </DialogTitle>

      <DialogContent>
        {`${t(userStatus ? "info-enable" : "info-disable", {
          ns: "user-list",
        })} ${userName}`}{" "}
      </DialogContent>

      <DialogActions>
        <Button disabled={isDeleting} color='tumblr' simple onClick={onClose}>
          {t("cancel")}
        </Button>

        <Button disabled={isDeleting} color={userStatus ? "success" : "danger"} onClick={onConfirm}>
          {t(userStatus ? "enable" : "disable")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
