import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Fingerprint } from "@material-ui/icons";
import AddAlert from "@material-ui/icons/AddAlert";
import { Box, Grid, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { cardTitle, primaryColor } from "assets/jss/material-dashboard-pro-react";
import AutoCompleteCustom from "components/AutoCompleteCustom";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import InputSearcher from "components/InputSearcher";
import Snackbar from "components/Snackbar/Snackbar";
import { useSubscription } from "hooks/useSubscription";
import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getConfigFromClientConfig } from "services/input-core/clientConfig/get-client-config";
import { AvailableValuesProps, FieldsProps } from "services/input-core/clientConfig/type";
import { useCreateSensitiveData } from "services/input-core/sensitive-data/create-sensitive-data";
import { getStrategy, StrategyArgs } from "helpers/formStrategyHelper/strategy-mapper";
import { useAuth } from "hooks/useAuth";
import { useTranslation } from "react-i18next";
import CustomSkeleton from "components/CustomSkeleton/CustomSkeleton";
import { CustomInputField } from "components/CustomInputField";
import { checkMaskLength, countriesInfo, masksByCountryCode, unMaskPhone } from "utils/masks";
import { RolesEnum } from "utils/@types";

type HRFieldsProps = {
  children?: React.ReactNode;
  sensitiveData: any;
  isAllSensitiveDataLoading: boolean;
  context?: Record<string, unknown>;
  type?: "workOrder" | "worker";
  workOrderId?: string;
};

const styles: any = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardHeader: {
    display: "flex",
    flexDirection: "column",
  },
  cardHeaderTag: {
    fontSize: "12px",
    fontWeight: "400",
    display: "flex",
    color: primaryColor[0],
    padding: "1px 2px !important",
    border: "1px solid",
    borderColor: primaryColor[0],
    borderRadius: "999px",
    width: "107px",
    height: "18px",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontSize: "16px",
    fontWeight: "400",
    color: "Black",
    webkitTouchCallout: "none",
    webkitUserSelect: "none",
    khtmlUserSelect: "none",
    mozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
  },
  saveButton: {
    color: primaryColor[0],
    variant: "outlined",
    BorderColor: primaryColor[0],
  },
};

const useStyles = makeStyles(styles);

interface SnackbarPayload {
  color: "success" | "warning" | "danger" | "info";
  message: string;
}

export default function WOSensitiveDataForm({
  isAllSensitiveDataLoading,
  sensitiveData,
  context,
  type,
  workOrderId,
}: HRFieldsProps) {
  const {
    register,
    setValue,
    watch,
    reset,
    formState: { errors },
    getValues,
    control,
  } = useForm();

  const classes = useStyles();
  const { subscription } = useSubscription();
  const queryClient = useQueryClient();
  const { hasRealmRole } = useAuth();
  const { t } = useTranslation("worker-profile");

  const [notification, setNotification] = useState({ open: false, message: "", color: "success" });
  const [clientConfig, setClientConfig] = useState();
  const [allSensitiveData, setAllSensitiveData] = useState({});
  const [prefix, setPrefix] = useState("");
  const [loadingPrefix, setLoadingPrefix] = useState(false);
  const [phoneNumberNotMatch, setPhoneNumberNotMatch] = useState(null);

  const sensitiveDataOptions = getConfigFromClientConfig(clientConfig, "WORK_ORDER_PROFILE");

  const fields = useMemo(
    () => sensitiveDataOptions?.steps?.reduce((acc, step) => [...acc, ...step.fields], []),
    [sensitiveDataOptions]
  );

  const [selectedCountry, setSelectedCountry] = React.useState(
    getPhoneNumberPrefix(sensitiveData) ? getPhoneNumberPrefix(sensitiveData) : prefix
  );

  const { mutate, isLoading: isSendingData } = useCreateSensitiveData();

  function getPhoneNumberPrefix(data: any) {
    const phoneNumber = data?.find((item: any) => item.label.default === "Phone Number").value;
    if (phoneNumber) {
      for (const countryCode in masksByCountryCode) {
        if (phoneNumber.startsWith(countryCode)) {
          return countryCode.toString();
        }
      }

      const countryCodeLength = phoneNumber.length >= 3 ? 3 : 2;
      const prefix = phoneNumber.substring(0, countryCodeLength);
      return prefix.toString();
    }
    return "";
  }

  function applyStrategy<T>(strategyKey: string, args: any): T {
    const strategy: StrategyArgs = getStrategy(strategyKey);

    return strategy.handle(args) as T;
  }

  function getFromContext(fieldName: string): unknown {
    return context ? context[fieldName] ?? null : null;
  }

  const getFieldForStrategy: any = {
    context: getFromContext,
    form: watch,
  };

  const buttonProps: any = {
    color: "primary",
    onClick: () => handleSubmit(),
    disabled: isSendingData || !hasRealmRole(["Supplier Company Rep"]),
  };

  useEffect(() => {
    setLoadingPrefix(true);
    if (sensitiveData) {
      setPrefix(getPhoneNumberPrefix(sensitiveData));
      setAllSensitiveData(sensitiveData);
    }
    setLoadingPrefix(false);
  }, [sensitiveData]);

  const [formData, setFormData] = useState<any>({
    initialState: {},
    currentState: {},
  });

  useEffect(() => {
    setClientConfig(JSON.parse(localStorage.getItem("clientConfig")));
  }, []);

  useEffect(() => {
    if (allSensitiveData) {
      let dataFormatted: any = null;

      if (sensitiveData) {
        dataFormatted = sensitiveData?.reduce((acc: any, item: any) => {
          acc[item.label.default] = item._id ?? item.value;
          return acc;
        }, {});
      }

      setFormData(() => ({
        initialState: dataFormatted,
        currentState: dataFormatted,
      }));
    }
  }, [isAllSensitiveDataLoading, allSensitiveData]);

  useEffect(() => {
    if (!fields) return;

    if (allSensitiveData)
      if (sensitiveData?.length > 0) {
        const loadedState: unknown = sensitiveData.reduce((prev: any, current: any) => {
          return {
            ...prev,
            [getNameByDisplayName(current?.label?.default)]: current?._id ?? current?.value,
          };
        }, {});

        return reset(loadedState);
      }
  }, [fields, allSensitiveData]);

  const handleSubmit = () => {
    const data = getValues();
    const phoneNumber = unMaskPhone(data?.phoneNumber);
    const verifyPhoneNumber = checkMaskLength(phoneNumber, selectedCountry);
    setPhoneNumberNotMatch(!verifyPhoneNumber);

    const formDataFormatted = Object.keys(data).map((key) => {
      let value = data[key];
      if (key === "phoneNumber") {
        value = unMaskPhone(value);
      }

      return {
        name: key,
        value: value,
      };
    });

    if (!verifyPhoneNumber) return;

    mutate(
      {
        refId:
          type === "worker" ? workOrderId : new URLSearchParams(window.location.search).get("id"),
        subscriptionId: subscription,
        formIdentifier: "WORK_ORDER_PROFILE",
        formData: formDataFormatted,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["sensitiveData"]);
          queryClient.invalidateQueries([
            "WorkOrder",
            new URLSearchParams(window.location.search).get("id"),
          ]);
          setFormData((prev: any) => ({
            ...prev,
            initialState: formData.currentState,
          }));
          showNotification({
            color: "success",
            message: "Saved successfully!",
          });
        },
        onError: (error: any) => {
          const errorMessage = Object.entries(watch()).some(
            ([key, value]) => !value && key !== "Age" && key !== "DateOfBirth"
          )
            ? "Please fill in all required fields before saving."
            : "Save failed!";
          showNotification({
            color: "danger",
            message: errorMessage,
          });
        },
      }
    );
  };

  console.log(watch())

  const getNameByDisplayName = (displayName: string) => {
    return fields?.find((field: any) => field.displayName.default === displayName)?.name;
  };

  function showNotification(payload: SnackbarPayload) {
    setNotification({ message: payload.message, color: payload.color, open: true });
  }

  useEffect(() => {
    setPhoneNumberNotMatch(false);
  }, [watch("phoneNumber")]);

  const renderFields = (field: FieldsProps) => {
    if (field.fieldType === "phoneNumber") {
      return (
        <Box display='flex' style={{ width: "100%" }} gap={5}>
          <FormControl>
            <InputLabel id='country-select-label'>Country</InputLabel>
            <Select
              style={{ width: "160px" }}
              labelId='country-select-label'
              id='country-select'
              value={selectedCountry}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                setSelectedCountry(e.target.value as string);
                setValue("phoneNumber", "");
                setPhoneNumberNotMatch(false);
              }}
              disabled={
                (field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)) ||
                !hasRealmRole([RolesEnum.SUPPLIER_COMPANY_REP])
              }
            >
              {countriesInfo.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box display='flex' flexDirection='column'>
            <CustomInputField
              countryCode={selectedCountry}
              label={field.displayName.default}
              name={field.displayName.default}
              style={{ flex: 1, marginTop: "11px", width: "200px" }}
              value={watch(field.name) || ""}
              type='text'
              required={field.required}
              control={control}
              rules={{
                required: true,
              }}
              {...register(field.name, { required: field.required })}
              disabled={
                (field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)) ||
                !hasRealmRole([RolesEnum.SUPPLIER_COMPANY_REP])
              }
            />
            {phoneNumberNotMatch && (
              <Typography style={{ color: "red", fontSize: 12 }}>
                Incomplete phone number
              </Typography>
            )}
          </Box>
        </Box>
      );
    }

    switch (field.fieldType) {
      case "string":
        return (
          <TextField
            id='standard-basic'
            variant='standard'
            label={field.displayName.default}
            style={{ width: "100%" }}
            value={watch(field.name) || ""}
            required={field.required}
            {...register(field.name, { required: field.required })}
            disabled={
              (field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)) ||
              !hasRealmRole(["Supplier Company Rep"])
            }
          />
        );

      case "readonlyWithStrategy":
        return (
          <TextField
            id='standard-basic'
            label={field.displayName.default}
            variant='standard'
            value={
              applyStrategy<string>(
                field.strategyKey,
                field.strategyArgsMapper.map((arg) => getFieldForStrategy[arg.type](arg.fieldName))
              ) || ""
            }
            type='button'
            InputLabelProps={{ shrink: true }}
            inputProps={{ readOnly: true, style: { textAlign: "left" } }}
            disabled
            style={{ width: "100%" }}
            {...register(field.name)}
          />
        );

      case "select":
        return (
          <Controller
            render={({ field: { onChange } }) => (
              <AutoCompleteCustom
                label={field.displayName.default}
                disablePortal
                value={
                  field?.availableValues?.find((a) => a?.value === watch(field?.name))?.displayName
                    ?.default ?? ""
                }
                id='outlined-select-currency'
                onChange={(e: SyntheticEvent<Element, Event>, data: { id: string }) => {
                  if (typeof data !== "string") onChange(data?.id ?? undefined);
                }}
                options={field.availableValues
                  .filter((option: AvailableValuesProps) =>
                    !option.showWhen
                      ? option
                      : watch(option.showWhen[0].key) === option.showWhen[0].value[0]
                  )
                  .map((option: AvailableValuesProps) => ({
                    id: option.value,
                    label: option.displayName.default,
                  }))}
                sx={{
                  width: "100% ",
                }}
                disabled={
                  (field?.allowWhenIsFilled && !watch(field?.allowWhenIsFilled)) ||
                  !hasRealmRole(["Supplier Company Rep"])
                }
              />
            )}
            rules={{
              required: field.required,
            }}
            name={field.name}
            control={control}
          />
        );

      case "searcher":
        return (
          <InputSearcher
            fieldData={field}
            hookFormWatch={watch}
            hookFormControl={control}
            disabled={!hasRealmRole(["Supplier Company Rep"])}
          />
        );
    }
  };

  return (
    <>
      {notification.open && (
        <Snackbar
          place='bc'
          color={notification.color}
          icon={AddAlert}
          message={notification.message}
          open={notification.open}
          closeNotification={() => setNotification({ open: false, message: "", color: "success" })}
          close
        />
      )}
      <Card>
        <CardHeader {...{ color: "warning", icon: true }}>
          <CardIcon {...{ color: "warning" }}>
            <Fingerprint />
          </CardIcon>
          <div className={classes.cardHeader}>
            <h4 className={classes.cardIconTitle}>{t("sensitive-data")}</h4>
          </div>
        </CardHeader>
        <CardBody>
          {isAllSensitiveDataLoading || loadingPrefix || !fields ? (
            <CustomSkeleton numberOfLines={5} />
          ) : (
            <>
              <Grid container spacing={2} style={{ width: "100%" }}>
                {fields?.map((field: FieldsProps) => (
                  <Grid
                    key={field.name}
                    item
                    xs={field.gridSize ?? 12}
                    style={{
                      display: !field!.showWhen
                        ? "inherit"
                        : field.showWhen[0].value[0] === watch(field.showWhen[0].key)
                        ? "inherit"
                        : "none",
                      paddingTop: field.fieldType === "checkbox" ? 12 : 24,
                    }}
                  >
                    {field.showWhen
                      ? field.showWhen[0].value[0] === watch(field.showWhen[0].key) &&
                        renderFields(field)
                      : renderFields(field)}
                    {errors[field.name] && (
                      <Grid key={field.name} item xs={field.gridSize ?? 12}>
                        This field is required
                      </Grid>
                    )}
                  </Grid>
                ))}
                {hasRealmRole(["Admin Support", "Supplier Company Rep"]) && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button type='button' variant='contained' {...buttonProps}>
                      {isSendingData ? <CircularProgress size={22} /> : "SAVE"}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}
